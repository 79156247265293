import {FaStoreAlt} from "react-icons/fa";
import React from "react";

function StoreIcon({size = "lg"}) {
    const sizeClasses = {
        sm: "w-4 h-4",
        md: "w-6 h-6",
        lg: "w-8 h-8",
    }[size];

    return (
        <FaStoreAlt className={`${sizeClasses} text-gray-300 rounded-full border shadow-md p-1`}/>
    );
}

export default StoreIcon;