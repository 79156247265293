import {createSlice} from '@reduxjs/toolkit'

const orderSlice = createSlice({
    name: 'order',
    initialState: {
      order: {},
      cart: {
        cartProducts: [],
      },
      currency: "eur",
      checkoutOption: {},
      cancelUrl: "",
      successUrl: "",
      accountId: null,
      merchant: {},
      merchantToken: "",
      type: "",
      data: {},
      customer: {},
      amount: 0,
    },
    reducers: {
      setMerchant: (state, action) => {
        state.merchant = action.payload
      },
      setOrder: (state, action) => {
        const checkoutSession = action.payload;
        state.order = checkoutSession
        state.cart = checkoutSession.cart
        state.currency = checkoutSession.currency
        state.checkoutOption = checkoutSession.checkoutOption
        state.cancelUrl = checkoutSession.cancelUrl
        state.successUrl = checkoutSession.successUrl
        state.accountId = checkoutSession.accountId
      },
      setCart: (state, action) => {
        state.cart = action.payload
      },
      setMerchantToken: (state, action) => {
        state.merchantToken = action.payload
      },
      setCheckoutOption: (state, action) => {
        state.checkoutOption = action.payload
      },
      setCurrency: (state, action) => {
        state.currency = action.payload
      },
      setCancelUrl: (state, action) => {
        state.cancelUrl = action.payload
      },
      setSuccessUrl: (state, action) => {
        state.successUrl = action.payload
      },
      setAccountId: (state, action) => {
        state.accountId = action.payload
      },
      setType: (state, action) => {
        state.type = action.payload
      },
      setData: (state, action) => {
        state.data = action.payload
      },
      setAmount: (state, action) => {
        state.amount = action.payload
      },
      setCustomer: (state, action) => {
        state.customer = action.payload
      }
    },
  }
)

export const {
  setOrder,
  setMerchant,
  setMerchantToken,
  setCart,
  setAccountId,
  setCancelUrl,
  setSuccessUrl,
  setCurrency,
  setCheckoutOption,
  setType,
  setData,
  setAmount,
  setCustomer
} = orderSlice.actions

export default orderSlice.reducer