import React from "react";
import {getCartTotal} from "../../utils/getCartTotal";
import {setError, setMessage} from "../../redux/payment";
import {createIntent} from "../../api/createIntent";

export const PaymentType = {
  Link: "link",
  Invoice: "invoice",
  Payment: "payment",
}

async function getPaymentIntent(order) {
  const {cart, merchant, merchantToken, data, type, amount, currency} = order;


  let paymentIntent;

  //merchant token is used to create payment intent
  const secretKey = merchant.is_live ? merchant.sk_live : merchant.sk_test;

  let totalAmount = getCartTotal(cart.cartProducts);

  if (type === PaymentType.Link) {
    if (data.type === 2) {
      if (data.is_limited && amount < data.limits.min) {
        throw new Error("Amount is less than minimum limit");
      } else if(amount < 100){
        throw new Error(`Le montant minimum est de 100 ${currency.toUpperCase()}`);
      }
      else {
        totalAmount = amount;
      }
    }
    paymentIntent = await createIntent(secretKey, totalAmount, currency, merchantToken, data, PaymentType.Link);
  } else if (type === PaymentType.Invoice) {
    paymentIntent = {
      intent: {
        client_secret: data.client_secret,
        intent_id: data.intent_id,
      }
    }
  } else {
    paymentIntent = {
      intent: {
        client_secret: data.intentSecret,
        intent_id: data.intentId,
      }
    }
  }

  return paymentIntent;
}

export default getPaymentIntent;