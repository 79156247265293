import axios from "axios";

export const getPaymentLink = (id, token) => {
    return axios.get(`${process.env.REACT_APP_PAYMENT_API}/api/v1/payment-links/${id}`,  {
        headers: {
            "Authorization": "Bearer " + token,
        }
    })
        .then(res => res.data).catch(err => {
            throw new Error(err);
        });
}

