import React, {useEffect, useState} from "react";
import StoreIcon from "../../@yb/components/icons/storeIcon";
import ProductItem from "./productItem";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {setMerchant, setOrder, setMerchantToken, setData, setType, setCart} from "../../redux/order";
import NumberFormat from "react-number-format";
import Footer from "../../@yb/components/footer";
import { getCheckoutSession } from "../../api/getCheckoutSession";
import { getToken } from "../../api/getToken";
import { getMerchant } from "../../api/getMerchant";
import { getCartTotal } from "../../utils/getCartTotal";
import Skeleton from "../../@yb/components/skeleton";
import {setCurrency, setLoading} from "../../redux/global";
import Spinner from "../../@yb/components/icons/spinner";
import YabetooLogo from "../../@yb/components/icons/yabetooLogo";
import Checkout from "../checkout";
import {PaymentType} from "../checkout/getPaymentIntent";

function Payment() {
  const location = useLocation();
  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {isLoading} = useSelector(state => state.global);

  const { data, accountId, cart, merchant } = useSelector(
    (state) => state.order
  );

  const { currency } = useSelector((state) => state.global);

  useEffect(() => {
    console.log("location", location);
    console.log("query", params.id);
    //dispatch(getCheckoutSession(params.id));
    init();
  }, []);

  const init = async () => {
    try {

      //get token
      const token = await getToken();
      dispatch(setType(PaymentType.Payment));
      const checkoutSession = await getCheckoutSession(params.id, token.token);
      dispatch(setData(checkoutSession));
      const merchant = await getMerchant(
        checkoutSession.accountId,
        token.token
      );

      dispatch((setCurrency(merchant.merchant?.country?.currency?.code.toUpperCase() ?? "EUR")));


      dispatch(setMerchant(merchant.merchant));
      dispatch(setMerchantToken(merchant.token.token));
      dispatch(setCart(checkoutSession.cart));
    } catch (error) {
      console.log("err", error);
      navigate("/404");
    } finally {
      dispatch(setLoading(false));
    }

  };

  return (
    <>
      {isLoading === true ? <Skeleton/> : <div className="grid grid-cols-1 sm:grid-cols-2 h-screen">
        <div className="flex flex-col items-center justify-center w-full px-10">
          <div className="max-w-xl w-full pt-10 md:pt-0">
            <div>
        <span className="inline-flex items-center space-x-2 text-ms leading-5 font-medium">
          <span>
            <StoreIcon />
          </span>
          <span>{merchant?.name}</span>
          {merchant?.is_live ? null : (
            <span className="bg-orange-600 uppercase rounded py-1 px-2 text-xs leading-5 text-white">
              Mode test
            </span>
          )}
        </span>
            </div>

            <div className="pt-10">
        <span className="text-5xl font-bold">
          <NumberFormat
            value={getCartTotal(data?.cart?.cartProducts)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={currency.toUpperCase()  + " "}
          />{" "}
        </span>
            </div>
            <div className="py-2 mb-2">
        <span className="text-4xl font-bold">
          {" "}
          <NumberFormat
            value={data.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={currency.toUpperCase()  + " "}
          />{" "}
        </span>
            </div>
            {data?.cart?.cartProducts.map((product, index) => {
              return <ProductItem key={index} product={product} />;
            })}
            <hr />

            <div className="flex justify-between py-10">
              <span>Sous-total</span>
              <span>
          <NumberFormat
            value={getCartTotal(data?.cart?.cartProducts)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={currency.toUpperCase() + " "}
          />{" "}
        </span>
            </div>
            <hr />


          </div>
        </div>
        <div className="sm:shadow-lg w-full px-10 flex flex-col items-center justify-center">
          <Checkout/>
        </div>
      </div>
      }
    </>
  );
}

export default Payment;
