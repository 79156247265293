import axios from "axios";

export const createIntent = (secretKey, amount, currency, token, data, origine) => {
    return axios.post(`${process.env.REACT_APP_PAYMENT_API}/api/v1/payment-intents`, {
        sk: secretKey,
        amount,
        currency,
        description: "Payment link",
        origine,
        metadata: {
            paymentLink : data.id
        }
    }, {
        headers: {
            "Authorization": "Bearer " + token,
        }
    })
        .then(res => res.data).catch(err => {
            throw new Error(err.response?.data?.error);
        });
}

