import React from 'react';
import YabetooLogo from "../icons/yabetooLogo";

function Index(props) {
    return (
      <div className='flex flex-col justify-center items-center pb-10'>
        <div className="flex items-center pt-10 flex-wrap justify-center">
          <div className="px-2">
            <span>Condition d'utilisation</span>
          </div>
          <div className="px-2">
            <span>Confidentialité</span>
          </div>
        </div>
        <div className="flex items-center text-gray-500 pr-2 mt-2">
          <span className="pr-2">Powered by </span>
          <YabetooLogo className="w-16 pt-1"/>
        </div>
      </div>

    );
}

export default Index;