import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getToken} from "../../api/getToken";
import {getPaymentLink} from "../../api/getPaymentLink";
import Spinner from "../../@yb/components/icons/spinner";
import YabetooLogo from "../../@yb/components/icons/yabetooLogo";
import Checkout from "../checkout";
import {setCurrency, setLoading} from "../../redux/global";
import {getMerchant} from "../../api/getMerchant";
import {setCart, setData, setMerchant, setMerchantToken, setType} from "../../redux/order";
import PaymentLinkComponent from "../../components/payment-link/PaymentLinkComponent";
import {PaymentType} from "../checkout/getPaymentIntent";
import {getCommand} from "../../api/getCommand";

function PaymentLink() {

  const {isLoading} = useSelector(state => state.global);
  const params = useParams();
  const dispatch = useDispatch();

  const { order, accountId, cart, merchant } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    init();
  }, []);


  const init = async () => {
    const token = await getToken();
    const paymentLink = await getPaymentLink(params.id, token.token);

    if (!paymentLink.active) {
      window.location.replace("/404");
    }

    dispatch(setData(paymentLink));
    dispatch(setType(PaymentType.Link));
    const merchant = await getMerchant(
      paymentLink.account_id,
      token.token
    );
    dispatch(setMerchant(merchant.merchant));
    dispatch(setMerchantToken(merchant.token.token));
    if(paymentLink.type === 1){
      const command = await getCommand(paymentLink.command_id, merchant.token.token);
      dispatch(setCart(command.cart));
    }

    dispatch((setCurrency(merchant.merchant?.country?.currency?.code.toUpperCase() ?? "EUR")));


    dispatch(setLoading(false));
  }

  return (
    <>
      {isLoading === true ? <div className="w-full h-screen flex items-center justify-center flex-col">
        <Spinner/>
        <YabetooLogo className="h-10 mt-6"/>
      </div> : <div className="grid grid-cols-1 sm:grid-cols-2 h-screen">
        <div className="flex flex-col items-center justify-center w-full px-10">
         <PaymentLinkComponent/>
        </div>
        <div className="sm:shadow-lg w-full px-10 flex flex-col items-center justify-center">
          <Checkout/>
        </div>
      </div>
      }
    </>
  );
}

export default PaymentLink;