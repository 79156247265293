import React from "react";
import axios from "axios";

export function confirmIntent  (clientSecret,name,email,phone, intentId, token, global)  {

    let payload = {
        "client_secret": clientSecret,
        "name": name,
        "email": email,
        "payment_method_data": {
            "type": "momo",
            "momo": {
                "country": global.country.code.toLowerCase(),
                "msisdn": phone,
                "operator_name": global.selectedOperator,
            }
        }
    };

    return axios.post(`${process.env.REACT_APP_PAYMENT_API}/api/v1/payment-intents/${intentId}/confirm`, payload, {
        headers: {
            "Authorization": "Bearer " + token,
        }
    })
        .then(res => res.data).catch(err => {
            throw new Error(err.response?.data?.error);
        });
}

