import axios from "axios";

export const getMerchant = (merchantId, token) => {
  return axios.post(`${process.env.REACT_APP_AUTH_API}/api/v1/yabetoo/merchant/token`, {
    secret_key: process.env.REACT_APP_SK_KEY,
    merchant_id: merchantId,
  }, {
    headers: {
      "Authorization": "Bearer " + token,
    }
  })
    .then(res => res.data).catch(err => {
      throw new Error(err);
    });
}

