import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Footer from "../../@yb/components/footer";
import NumberFormat from "react-number-format";
import LineItem from "./lineItem";
import StoreIcon from "../../@yb/components/icons/storeIcon";
import dayjs from "dayjs";
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import fr from 'dayjs/locale/fr'
import {getToken} from "../../api/getToken";
import {setCustomer, setData, setMerchant, setMerchantToken, setType} from "../../redux/order";
import {getMerchant} from "../../api/getMerchant";
import {useNavigate} from "react-router-dom";
import {setCurrency, setLoading} from "../../redux/global";
import {PaymentType} from "../checkout/getPaymentIntent";
import Checkout from "../checkout";
import {getInvoice} from "../../api/getInvoice";
import {getCustomer} from "../../api/getCustomer";
import Skeleton from "../../@yb/components/skeleton";

dayjs.locale(fr)
dayjs.extend(LocalizedFormat)

function Index() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {isLoading} = useSelector(state => state.global);

  const {data, merchant, customer} = useSelector((state) => ({
    ...state.order,
  }));

  useEffect(() => {
    init();
  }, [id]);

  const init = async () => {
    try {
      setLoading(true);
      //get token
      const token = await getToken();
      dispatch(setType(PaymentType.Invoice));
      console.log({id, token: token.token})
      const invoice = await getInvoice({id, token: token.token});
      dispatch(setData(invoice));
      let merchant = await getMerchant(invoice.account_id, token.token);
      dispatch(setMerchant(merchant.merchant));
      dispatch(setMerchantToken(merchant.token.token));
      dispatch(setCustomer(invoice.customer));

      dispatch((setCurrency(merchant.merchant?.country?.currency?.code.toUpperCase() ?? "EUR")));


    } catch (error) {
      navigate("/404");
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };


  return (
    <>
      {isLoading === true ? <Skeleton/> : data?.items && (
        <div className="grid grid-cols-1 sm:grid-cols-2 h-screen">
          <div className="flex flex-col items-center justify-center w-full px-10">
            <div className="max-w-xl w-full">
              <div>
          <span className="inline-flex items-center space-x-2 text-ms leading-5 font-medium">
            <span>
              <StoreIcon/>
            </span>
            <span>{merchant?.name}</span>
            {merchant?.is_live === 0 && (
              <span className="bg-orange-600 uppercase rounded py-1 px-2 text-xs leading-5 text-white">
              Mode test
            </span>
            )}
          </span>
              </div>

              <div className="pt-10">
                <span>À régler avant le {dayjs(data?.due_date).format('LL')} </span>
              </div>
              <div className="py-2 mb-2">
          <span className="text-4xl font-bold">
            <NumberFormat
              value={data?.amount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$ "}
            />
          </span>
              </div>

              <div className="grid grid-cols-2 gap-2 gap-x-2 py-5 mb-2">
                <div className="text-gray-400">À</div>
                <div>{customer?.name}</div>
                <div className="text-gray-400">De</div>
                <div>{merchant?.name}</div>
                <div className="text-gray-400">Facture</div>
                <div>{data?.number}</div>
              </div>

              <hr/>

              {data?.items.map((product, index) => {
                return <LineItem key={index} products={product}/>;
              })}

              <hr/>

              <div className="flex justify-between py-10">
                <span>Sous-total</span>
                <span>
            <NumberFormat
              value={data?.amount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"€ "}
            />
          </span>
              </div>

              <Footer/>
            </div>
          </div>
          <div className="sm:shadow-lg w-full px-10 flex flex-col items-center justify-center">
            <Checkout/>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;