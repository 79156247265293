import React from "react";

function YabetooIcon({className}) {
    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266.07 200.49">
        <defs>
          <style>.cls-1{'fill:#ff5224;'}.cls-2{'fill:#3956ff;'}.cls-3{'fill:#d63313;'}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className="cls-1"
                  d="M110.58,64.16a184.53,184.53,0,0,0-36.38,65A80.34,80.34,0,0,1,95,169.28h2.56a153.11,153.11,0,0,1,35.48-83.42A153.7,153.7,0,0,0,110.58,64.16Zm90.16,136.33V184.88a49.79,49.79,0,0,1,49.73-49.75h15.6V0h-15.6A184,184,0,0,0,133.05,42.21a186.06,186.06,0,0,1,22.43,21.95A152.93,152.93,0,0,1,234.89,32v73.48a80.88,80.88,0,0,0-43,23.71,183.62,183.62,0,0,1,8.59,55.72v15.58m-134.85,0v0"/>
            <path className="cls-2"
                  d="M15.58,0H0V135.13H15.58a49.81,49.81,0,0,1,49.77,49.75l5.09,15.58H65.61V184.88a184.24,184.24,0,0,1,8.59-55.72,80.81,80.81,0,0,0-43-23.71V32a152.83,152.83,0,0,1,79.4,32.19,184.89,184.89,0,0,1,22.47-21.95A184.13,184.13,0,0,0,15.58,0ZM171.06,169.31a80.71,80.71,0,0,1,20.81-40.15,184.4,184.4,0,0,0-36.39-65,153.42,153.42,0,0,0-22.43,21.7,153,153,0,0,1,35.44,83.42m-70.92,0v0"/>
            <path className="cls-3"
                  d="M155.49,64.15A154.16,154.16,0,0,0,133,85.86a155.18,155.18,0,0,0-22.45-21.71A184.66,184.66,0,0,1,133,42.21,186.88,186.88,0,0,1,155.49,64.15Z"/>
            <path className="cls-1"
                  d="M97.57,169.28v0c-.88,9.63-1.21,21.47-1.32,31.15H65.61V184.88a184.24,184.24,0,0,1,8.59-55.72A80.34,80.34,0,0,1,95,169.28Z"/>
            <path className="cls-2"
                  d="M200.46,184.88v15.58H96.25c.11-9.68.44-21.52,1.32-31.15h73.49a80.71,80.71,0,0,1,20.81-40.15A183.62,183.62,0,0,1,200.46,184.88Z"/>
          </g>
        </g>
      </svg>
    );
}

export default YabetooIcon;