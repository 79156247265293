import axios from "axios";
const { REACT_APP_ADMIN_INVOICE_API_URL } = process.env;


export const getInvoice = (payload) => {
  console.log("getInvoice", payload);
  return axios.get(
    `${REACT_APP_ADMIN_INVOICE_API_URL}/invoice/${payload.id}`, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      }
    }
  )
    .then(res => res.data).catch(err => {
      console.log("getInvoice", err);
      throw new Error(err);
    });
}

export const updateInvoiceStatus = (payload) => {
  return axios.post(
    `${REACT_APP_ADMIN_INVOICE_API_URL}/invoice/${payload.id}/paid`, {},{
      headers: {
        Authorization: `Bearer ${payload.token}`,
      }
    }
  )
    .then(res => res.data).catch(err => {
      throw new Error(err);
    });
}

