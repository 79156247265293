import axios from "axios";

export const getCheckoutSession = (sessionId, token) => {

  return axios.get(`${process.env.REACT_APP_STORE_URL}/api/checkout/${sessionId}`, {
    headers: {
      "Authorization-Token": "Bearer " + token,
    },
  }).then(res => res.data)
    .catch(err => {
      throw new Error(err);
    });
}

