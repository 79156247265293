import React from 'react';
import NumberFormat from 'react-number-format';
import {useSelector} from "react-redux";
import {getCartTotal} from "../../utils/getCartTotal";

function ProductItem(props) {

    const {data, cart} = useSelector((state) => state.order);
    const {currency} = useSelector((state) => state.global);

    return (
        <div className="flex justify-between py-2">
            <div className="flex flex-col">
                <span>{props.product.product.name}</span>
                <span className="text-sm text-gray-500 py-1">Quantity: {props.product.quantity}</span>
            </div>
            <div className="flex flex-col items-end">
                <span><NumberFormat value={getCartTotal(cart?.cartProducts)} displayType={'text'} thousandSeparator={true} prefix={currency.toUpperCase()  +' '} /></span>
                <span className="text-sm text-gray-500 py-1"><NumberFormat value={props.product.product.price} displayType={'text'} thousandSeparator={true} prefix={currency.toUpperCase()  +' '} /> / piece</span>
            </div>
        </div>
    );
}

export default ProductItem;