import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_ADMIN_INVOICE_API_URL } = process.env;

export const getInvoices = createAsyncThunk(
  "invoice/getInvoices",
  async (payload,) => {

      return await axios.get(
        `${REACT_APP_ADMIN_INVOICE_API_URL}/yabetoo/invoice/${payload.id}`, {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          }
        }
      );
  }
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoice: null,
    loading: false,
    error: null,
  },

  extraReducers: {
    [getInvoices.pending]: (state, action) => {
      state.isloading = true;
    },
    [getInvoices.fulfilled]: (state, action) => {
      state.isloading = false;
      state.invoice = action.payload.data;
    },
    [getInvoices.rejected]: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
  },
});

export default invoiceSlice.reducer;