import React from "react";

function YabetooLogo({className}) {
    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452.88 115.88">
        <defs>
          <style>.cls-1{'fill:#001c6b;'}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect className="cls-1" x="215.35" y="4.82" width="39.48" height="10.06"/>
            <path className="cls-1" d="M65.66,25,25,115.88H7.73l16.35-36.7L0,25H17.27L32.72,61.57,48.28,25Z"/>
            <path className="cls-1"
                  d="M126.6,25l-.11,62.49H110.24V79.07A23.2,23.2,0,0,1,90.7,88.84c-16.36,0-28.51-13.41-28.51-32.61s12.38-32.6,28.4-32.6a23.48,23.48,0,0,1,19.77,10.11V25ZM110.36,56.23c0-10.22-6.93-17.15-16-17.15S78.55,46,78.55,56.23s6.81,17.16,15.79,17.16S110.36,66.57,110.36,56.23Z"/>
            <path className="cls-1"
                  d="M199.09,56.23c0,19.2-12.27,32.61-28.51,32.61a23.63,23.63,0,0,1-19.77-9.77v8.41H134.56V11.7A10,10,0,0,1,142.27,2l8.54-2V33.74a23.62,23.62,0,0,1,19.77-10.11C186.59,23.63,199.09,36.92,199.09,56.23Zm-16.47,0c0-10.22-6.71-17.15-15.79-17.15s-16,6.93-16,17.15,6.93,17.16,16,17.16S182.62,66.57,182.62,56.23Z"/>
            <path className="cls-1"
                  d="M267.82,60.44H219.88c1,10,7.84,15,16.25,15,6.25,0,11.7-2.84,13.86-7.84H266.8c-4.66,14.77-17.15,21.25-30.56,21.25C216.7,88.84,203.3,75,203.3,56.35s13.52-32.72,32.6-32.72C256.58,23.63,269.41,39.88,267.82,60.44ZM220.68,48.51h29.88C248.74,41.69,243.63,37,235.79,37S222.72,41.81,220.68,48.51Z"/>
            <path className="cls-1"
                  d="M320.46,83.84c-3.41,2.27-11.13,5-18.63,5-12.5,0-23.4-7.5-23.4-25.45V40.33H267.29V25h11.14V18.92a9.23,9.23,0,0,1,7.28-9l9-1.95V25h23.18V40.33H294.67V62.48c0,7.39,3.53,11.25,10,11.25,3.64,0,7.95-1.25,13.07-3.07Z"/>
            <path className="cls-1"
                  d="M383.89,56.23c0,18.86-14.31,32.61-32.6,32.61S318.8,75,318.8,56.23s14.54-32.6,32.49-32.6S383.89,37.38,383.89,56.23Zm-48.74,0c0,10.34,7.05,17.16,16.14,17.16s16.13-6.82,16.13-17.16-7.05-17.15-16.13-17.15S335.15,46,335.15,56.23Z"/>
            <path className="cls-1"
                  d="M452.88,56.23c0,18.86-14.32,32.61-32.61,32.61S387.78,75,387.78,56.23s14.54-32.6,32.49-32.6S452.88,37.38,452.88,56.23Zm-48.74,0c0,10.34,7.05,17.16,16.13,17.16s16.14-6.82,16.14-17.16-7.05-17.15-16.14-17.15S404.14,46,404.14,56.23Z"/>
          </g>
        </g>
      </svg>
    );
}

export default YabetooLogo;