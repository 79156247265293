async function poll(request) {
    let response;
    for (let i = 0; i <= 6; i++) {
        response = await request();
        console.log('polling for status', response, 'attempt', i);
        if (response.transfer) {
            return response;
        }
        await new Promise(resolve => setTimeout(resolve, 10000));
    }
    return response;
}

export default poll;
