import {PaymentType} from "./getPaymentIntent";
import notification from "../../@yb/notification";
import {updateInvoiceStatus} from "../../api/getInvoice";
import {transferIntent} from "../../api/transfer";

const PaymentStatus = {
  Success: "succeeded",
  Failed: "failed",
}

const redirectUser = async (confirmPayment, paymentIntent, order) => {


  if (confirmPayment.status === PaymentStatus.Success) {

    notification.success("Payment successful");

    if (order.type === PaymentType.Link) {
      IsPaymentLink(order)
    }
    if (order.type === PaymentType.Invoice) {
      await IsPaymentInvoice(order)
    }
    if (order.type === PaymentType.Payment) {
      IsPayment(confirmPayment.status, paymentIntent, order)
    }

/*
    await transferIntent(paymentIntent.intent.intent_id, order.merchantToken).finally(() => {});
*/

  } else {
    notification.error("Payment failed");
    if (order.type === PaymentType.Payment) {
      IsPayment(confirmPayment.status, paymentIntent, order)
    }
  }
}

const IsPaymentLink = (order) => {
  console.log("IsPaymentLink");
  window.location.replace("/success");
}

const IsPaymentInvoice = async (order) => {
  console.log("IsPaymentInvoice");
  await updateInvoiceStatus({accountId: order.data.account_id, id: order.data.id, token: order.merchantToken});
  window.location.replace("/success");
}

const IsPayment = (status, paymentIntent, order) => {
  if (status === PaymentStatus.Success) {
    window.location.replace(`${order.data.successUrl}?paymentId=${paymentIntent.intent.intent_id}&orderId=${order.data.metadata?.orderId}`);
  } else {
    window.location.replace(`${order.data.cancelUrl}?paymentId=${paymentIntent.intent.intent_id}&orderId=${order.data.metadata?.orderId}`);
  }
}

export default redirectUser