import React, {useEffect, useState} from "react";
import localData from "../../data";
import {useDispatch, useSelector} from "react-redux";
import {
  setCountry,
  setOperatorList,
  setSelectedCountry,
  setSelectedOperator,
} from "../../redux/global";

import {confirmIntent} from "../../api/confirmIntent";
import YabetooIcon from "../../@yb/components/icons/yabetooIcon";
import MobileMoneyInput from "../../@yb/components/input/mobileMoneyInput";
import Footer from "../../@yb/components/footer";
import getPaymentIntent from "./getPaymentIntent";
import redirectUser from "./redirectUser";
import notification from "../../@yb/notification";
import {Formik} from "formik";

function Checkout() {
  const global = useSelector((state) => state.global);
  const order = useSelector((state) => state.order);
  const payment = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentMode, setPaymentMode] = useState("mobile");


  function getOperator() {
    switch (global.selectedCountry) {
      case "congo":
        dispatch(setOperatorList(localData.operator.congo));
        dispatch(setCountry(localData.country[0]));
        break;
      case "niger":
        dispatch(setOperatorList(localData.operator.niger));
        dispatch(setCountry(localData.country[1]));

        break;
      case "drcongo":
        dispatch(setOperatorList(localData.operator.drcongo));
        dispatch(setCountry(localData.country[2]));
        break;
      default:
        return dispatch(setOperatorList([]));
    }
  }

  useEffect(() => {
    if (global.selectedCountry) {
      getOperator();
    }
  }, [global.selectedCountry]);

  const makePayment = async (values) => {

    try {
      setLoading(true);
      const paymentIntent = await getPaymentIntent(order);

      const confirmPayment = await confirmIntent(paymentIntent.intent.client_secret, values.name, values.email, phone, paymentIntent.intent.intent_id, order.merchantToken, global);
      redirectUser(confirmPayment, paymentIntent, order);
    } catch (e) {
      notification.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const onCountryChange = (e) => {
    dispatch(setSelectedCountry(e.target.value));
  }

  return (
    <div className="max-w-xl w-full pt-10">
      <h1 className="text-gray-400">Choisissez un moyen de paiement.</h1>
      <div className="pt-4 flex">
        <div onClick={() => setPaymentMode('mobile')}
             className={`${paymentMode === "mobile" ? "border-black" : null} border w-44 rounded-lg p-2 cursor-pointer`}>
          <YabetooIcon className="w-8 mb-1"/>
          <p>Mobile Money</p>
        </div>
      </div>


      <Formik
        initialValues={{email: "", name: ""}}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Veuillez entrer votre email.';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          if (!values.name) {
            errors.name = 'Veuillez entrer votre nom.';
          }
          console.log(errors)
          return errors;
        }}
        onSubmit={makePayment}
      >

        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
          <form onSubmit={handleSubmit}>
            <div className="w-full mt-6">
              <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-5">
                Information personnelles
              </label>

              {Object.keys(errors).length !== 0 && (
                <div className="my-5 ml-3">
                  <ul className="list-disc">
                    <li className="text-red-700"> {errors.email && touched.email && errors.email}</li>
                    <li className="text-red-700">{errors.name && touched.name && errors.name}</li>
                  </ul>
                </div>
              )}
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="w-full py-1  border-gray-300 pl-2 rounded-t-md border border-b-transparent text-gray-500"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />

              <input
                type="text"
                name="name"
                placeholder="Nom et prénom"
                className="border w-full border-gray-300 rounded-b-md py-1 pl-2  text-gray-500"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />

            </div>

            <MobileMoneyInput countries={localData.country} onCountryChange={onCountryChange}
                              operators={global.operatorList}
                              format={global.country?.format} onPhoneChange={(event) => setPhone(event.value)}
                              onOperatorChange={(event) => dispatch(setSelectedOperator(event.target.value))}/>


            <div className="w-full pt-6">
              <button
                disabled={loading || phone.length === 0}
                className="flex items-center justify-center bg-indigo-500 text-white active:bg-pink-600 font-bold  text-sm px-4 py-3 rounded shadow w-full hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
              >
                {loading ? (
                  <svg
                    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : (
                  <span className="font-medium subpixel-antialiased">Payer</span>
                )}
              </button>
            </div>

          </form>
        )}


      </Formik>

      <Footer/>
    </div>
  );
}

export default Checkout;
