import React, { Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import App from "./App";
import Transfer from "./@yb/components/transfer";
import NotFound from "./pages/404";
import SuccessPage from "./pages/success";

const routes = [
    {path: '/invoice/:id', Component: App},
    {path: '/pay/:id', Component: App},
    {path: '/link/:id', Component: App},
    {path: '/transfer', Component: Transfer},
    {path: '/success', Component: SuccessPage},
    {path: '/404', Component: NotFound},
    {path: '*', Component: NotFound},
]

const Router = () => {
    return (
            <Routes>
                {routes.map(({path, Component}) => (
                    <Route key={path}  path={path} element={
                        <Suspense fallback={null}>
                            <Component/>
                        </Suspense>
                    }/>
                ))}
            </Routes>
    )
}

export default Router