import React from 'react';
import NumberFormat from "react-number-format";

function MobileMoneyInput(props) {

  const {countries, onCountryChange, operators, format, onPhoneChange, onOperatorChange} = props;
  return (
    <div className="mt-6">
      <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-5">
        Information mobile money
      </label>
      <select
        onChange={onCountryChange}
        className="focus:outline-0 block w-full  pr-12 sm:text-sm border-gray-300 rounded-t-md border-t border-b-transparent text-gray-500"
      >
        <option>Selectionner un pays</option>
        {countries.map((country, index) => (
          <option key={index} value={country.value}>
            {country.label}
          </option>
        ))}
      </select>
      <div className="flex flex-col md:flex-row rounded-md shadow-sm">
        <div className=" flex items-center md:w-1/2">
          <select
            onChange={onOperatorChange}
            className="focus:ring-indigo-500 block  focus:border-indigo-500 h-full  pl-2 pr-7 bg-transparent border-gray-300 text-gray-500 sm:text-sm w-full md:rounded-bl-md"
          >
            <option>Selectionner un operateur</option>
            {operators.map((operator, index) => (
              <option key={index} value={operator.value}>
                {operator.label}
              </option>
            ))}
          </select>
        </div>

        <div className="md:w-1/2">
          <NumberFormat
            type="tel"
            onValueChange={(event) => onPhoneChange(event)}
            format={format}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-l-transparent border-t-transparent md:border-t-gray-300 border-l-gray-300 md:border-l-0 border-gray-300 md:rounded-br-md rounded-b-md md:rounded-bl-none"
            placeholder="Numéro de telephone"
          />
        </div>
      </div>
    </div>
  );
}

export default MobileMoneyInput;