import axios from "axios";

export const getToken = () => {

    return axios.post(`${process.env.REACT_APP_AUTH_API}/api/v1/token`, {
        secretKey: process.env.REACT_APP_SK_KEY,
    }).then(res => res.data).catch(err => {
        throw new Error(err);
    });
}

