import React from 'react';
import SuccessIcon from "../../@yb/components/icons/successIcon";

function SuccessPage(props) {
  return (
    <div className="sm:shadow-lg w-full flex flex-col items-center justify-center px-10 pt-10 h-screen">
      <SuccessIcon className="w-16"/>

        <div className="w-full flex flex-col items-center justify-center mt-4">
          <p>Thank you for your payment</p>
        </div>
    </div>
  );
}

export default SuccessPage;