import Checkout from "./pages/checkout";
import Payment from "./pages/payment";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import Invoice from "./pages/invoice";
import {useDispatch, useSelector} from "react-redux";
import {setIsInvoice} from "./redux/global";
import Skeleton from "./@yb/components/skeleton";
import NotFound from "./pages/404";
import YabetooLogo from "./@yb/components/icons/yabetooLogo";
import Spinner from "./@yb/components/icons/spinner";
import PaymentLink from "./pages/link";
import {Toaster} from "react-hot-toast";


function getChildren(path) {
  switch (path) {
    case 'pay':
      return <Payment/>;
    case 'invoice':
      return <Invoice/>;
    case 'link':
      return <PaymentLink/>;
    default:
      return <NotFound/>;
  }
}

function App() {
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState(location.pathname?.split('/')[1]);
  return (
     <>
       <div><Toaster/></div>
       {getChildren(currentPath)}
     </>

  );
}

export default App;
