import React from "react";
import NumberFormat from "react-number-format";

function LineItem({ products }) {
  return (
    <div className="flex justify-between py-2">
      <div className="flex flex-col">
        <span>{products?.product?.name}</span>
        <span className="text-sm text-gray-500 py-1">
          Quantity: {products?.quantity}
        </span>
      </div>
      <div className="flex flex-col items-end">
        <span>
          <NumberFormat
            value={products?.product?.price * products?.qty}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$ "}
          />
        </span>
        <span className="text-sm text-gray-500 py-1">
          <NumberFormat
            value={products?.product?.price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$  "}
          />{" "}
          / piece
        </span>
      </div>
    </div>
  );
}

export default LineItem;