import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

export const getPayment = createAsyncThunk(
    'payment/getPayment',
    async (payload) => {
        return axios.post(`${process.env.REACT_APP_PAYMENT_API}/${payload.path}`, payload.data, {
            headers: {
                "Authorization": "Bearer " + process.env.REACT_APP_TOKEN,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.data).catch(err => err);
    }
);


const paymentSlice = createSlice({
        name: 'payment',
        initialState: {
            isLoading: false,
            message: '',
            error: null,
            payment: null,
        },
        reducers: {
            setLoading: (state, action) => {
                state.isLoading = action.payload
            },
            setMessage: (state, action) => {
                state.message = action.payload
            },
            setError: (state, action) => {
                state.error = action.payload
            },
        },
        extraReducers: {
            [getPayment.pending]: (state, action) => {
                state.isLoading = true;
            },
            [getPayment.fulfilled]: (state, action) => {
                state.isLoading = false;
                state.order = action.payload;

                try {

                    if (action.payload.transaction.status === 'succeeded') {
                        state.message = "Payment Successful, you'll be redirected to the merchant webpage in a few seconds.";
                        state.error = false;
                    } else {
                        state.message = "Payment Failed";
                        state.error = true;
                    }
                } catch (e) {
                    state.message = "Payment Failed";
                    state.error = true;
                }
            },
            [getPayment.rejected]: (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            },
        },
    }
)

export const {setLoading, setError, setMessage} = paymentSlice.actions

export default paymentSlice.reducer