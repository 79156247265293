import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {store} from './redux/store';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import Router from "./router";
import  { Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51JCTjmAXkircnyUy3wfDq6SSzXehPJe3UI05XwbvKmwgHJGJwg00rHBwsnTiVPyEDLxbQCLXYlNaLKOfVs3zzqh000cRB2mn39');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Elements stripe={stripePromise}>
            <Router/>
            </Elements>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
