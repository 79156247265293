import axios from "axios";

export const getCommand = (id, token) => {

  return axios.get(`${process.env.REACT_APP_STORE_URL}/api/command/${id}`, {
    headers: {
      "Authorization-Token": "Bearer " + token,
    },
  }).then(res => res.data)
    .catch(err => {
      throw new Error(err);
    });
}

