import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setOperatorList, setSelectedCountry, setSelectedOperator} from "../../../redux/global";
import data from "../../../data";
import Select from "../select";
import NumberFormat from "react-number-format";
import  {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import Footer from "../footer";
import poll from "../../../utils/poll";

function Transfer(props) {
    const global = useSelector(state => state.global);
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("")
    const [amount, setAmount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState("")
    const [commission, setCommission] = useState({})


    const elements = useElements();
    const stripe = useStripe();


    function getOperator() {
        switch (global.selectedCountry.value) {
            case 'congo':
                dispatch(setOperatorList(data.operator.congo))
                break;
            default:
                return null
        }
    }

    useEffect(() => {
        if(global.selectedCountry){
            getOperator()
        }
    },[])

    const getCommission = async (price) => {
        const {commission} = await fetch(`${process.env.REACT_APP_PAYMENT_API}/stripe/commission?country=${global.selectedCountry.code}&amount=${price}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).catch(err => err);

        console.log(commission)
        setCommission(commission)
    }



    const makePayment = async (e) => {
        e.preventDefault();
        if(!stripe || !elements) {
            return;
        }
        setIsLoading(true)
        setMessage("")
        setError(null)

        // create a payment intent on the server
        const {clientSecret} = await fetch(`${process.env.REACT_APP_PAYMENT_API}/stripe/create-payment-intent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amount: commission.amount,
                transfer_amount: commission.transfer_amount,
                country: global.selectedCountry.code,
                operator: global.selectedOperator.value,
                msisdn: phone,
                initial_amount: amount,
            }),
        }).then(res => res.json());

        console.log(clientSecret)

        const {error: stripeError,paymentIntent} =  await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            }
        })

        if(stripeError){
            console.log(stripeError)
            setError(stripeError)
            setIsLoading(false)
            setMessage(stripeError.message)
            return;
        }


        const {transfer, stripePaymentIntent} = await poll(() => fetch(`${process.env.REACT_APP_PAYMENT_API}/stripe/payment-intent/${paymentIntent.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).catch(err => err))

        if(transfer === null){
            setIsLoading(false)
            setError("Something went wrong")
            setMessage("Un problème est survenu lors du transfer, un remboursement vous sera envoyé")
            return;
        }

        setMessage("Votre transfert a été effectué avec succès")

        setIsLoading(false)

        console.log(`PaymentIntent status: ${paymentIntent.status}, paymentIntent id: ${paymentIntent.id}`, transfer, stripePaymentIntent)

    }

    return (
        <form id="payment-form" onSubmit={makePayment}>
                <div className="max-w-xl w-full mx-auto mt-20 px-4">
                    <h1 className="text-xl">
                        Envoyer de l'argent a vos proches
                    </h1>

                    <div className="w-full pt-8">
                        <div className="relative w-full">
                            <label className="block leading-5 text-gray-500 text-sm mb-2"
                                   htmlFor="grid-password">
                                Pays
                            </label>
                            <Select
                                options={data.country}
                                label="Select your country"
                                selectedOption={global.selectedCountry}
                                handelChange={(event) => {
                                    dispatch(setSelectedCountry(event))
                                    console.log("parent", event);

                                }}
                            />
                        </div>
                    </div>

                    <div className="w-full pt-6">
                        <div className="relative w-full mb-3">
                            <label className="block leading-5 text-gray-500 text-sm mb-2"
                                   htmlFor="grid-password">
                                Opérateur
                            </label>
                            <Select
                                options={global.operatorList}
                                selectedOption={global.selectedOperator}
                                handelChange={(event) => {
                                    console.log("parent", event);
                                    dispatch(setSelectedOperator(event))
                                }}
                            />
                        </div>
                    </div>

                    <div className="w-full pt-4">
                        <div className="relative w-full mb-3">
                            <label className="block leading-5 text-gray-500 text-sm mb-2"
                                   htmlFor="grid-password">
                                Montant du transfert
                            </label>
                            <NumberFormat onValueChange={(e) => {
                                console.log(e.floatValue)
                                if(e.floatValue){
                                    setAmount(e.floatValue)
                                    getCommission(e.floatValue)
                                } else {
                                    setAmount(0)
                                }

                            }} thousandSeparator={true} prefix={'€ '}  className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>

                        </div>
                    </div>
                    <div className="w-full pt-4">
                        <div className="relative w-full mb-3">
                            <label className="block leading-5 text-gray-500 text-sm mb-2"
                                   htmlFor="grid-password">
                                Numero de telephone
                            </label>
                            <NumberFormat onValueChange={(event) => setPhone(event.value)} disabled={global.selectedOperator === null} type="tel" format={global.selectedCountry?.format} mask="_"
                                          className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                          placeholder="phone" />

                        </div>
                    </div>

                    <hr/>

                    <div className="flex items-center justify-between shadow py-4 px-2 border rounded">
                        <span> Montant total :  <NumberFormat displayType={'text'}  value={commission.amount} thousandSeparator={true} prefix={'€ '} /></span>
                        <span> Montant envoyé :  <NumberFormat displayType={'text'}  value={commission.transfer_amount} thousandSeparator={true} prefix={commission.currency + ' '} /></span>
                    </div>

                    <hr/>

                    <div className="w-full pt-4">
                        <div className="relative w-full mb-3">
                            <label className="block leading-5 text-gray-500 text-sm mb-2"
                                   htmlFor="grid-password">
                                Information de paiement
                            </label>
                            <CardElement  className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>


                        </div>
                    </div>


                    <span className={`${
                        error ? "text-red-500" : "text-green-500"
                    } py-4 `}>{message}</span>

                    <div className="w-full pt-4">
                        <button disabled={isLoading || phone.length === 0}
                                className="flex items-center justify-center bg-indigo-500 text-white active:bg-pink-600 font-bold  text-sm px-4 py-3 rounded shadow w-full hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="submit">
                            {isLoading ? <svg className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"/>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                            </svg> : <span className="font-medium subpixel-antialiased">Envoyer</span>}
                        </button>
                    </div>

                    <Footer/>

                </div>
        </form>
    );
}

export default Transfer;