import React from 'react';
import StoreIcon from "../../@yb/components/icons/storeIcon";
import NumberFormat from "react-number-format";
import {getCartTotal} from "../../utils/getCartTotal";
import ProductItem from "../../pages/payment/productItem";
import {useDispatch, useSelector} from "react-redux";
import {setAmount} from "../../redux/order";

function PaymentLinkComponent(props) {

  const {data, cart, merchant, amount} = useSelector(
    (state) => state.order
  );

  const dispatch = useDispatch();


  const {currency} = useSelector((state) => state.global);


  return (
    <div className="max-w-xl w-full pt-10 md:pt-0">
      <div>
        <span className="inline-flex items-center space-x-2 text-ms leading-5 font-medium">
          <span>
            <StoreIcon/>
          </span>
          <span>{merchant?.name}</span>
          {merchant?.is_live ? null : (
            <span className="bg-orange-600 uppercase rounded py-1 px-2 text-xs leading-5 text-white">
              Mode test
            </span>
          )}
        </span>
      </div>

      {data?.type === 1 ? (
        <>
          <div className="py-10">
        <span className="text-5xl font-bold">
          <NumberFormat
            value={getCartTotal(cart.cartProducts)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={currency.toUpperCase() + " "}
          />{" "}
        </span>
          </div>

          {cart?.cartProducts.map((product, index) => {
            return <ProductItem key={index} product={product}/>;
          })}
          <hr/>

          <div className="flex justify-between py-10">
            <span>Sous-total</span>
            <span>
          <NumberFormat
            value={getCartTotal(cart.cartProducts)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={currency.toUpperCase() + " "}
          />{" "}
        </span>
          </div>
          <hr/>
        </>
      ) : (
        <div className='pt-5'>
          <label htmlFor="price" className="block  font-medium text-gray-700 mb-1">
            {data.title}
          </label>
          <div className="text-gray-500 py-1 mt-5">
            <NumberFormat
              placeholder="Sasir un montant"
              onValueChange={(val) => dispatch(setAmount(val.floatValue))}
              //value={amount}
              className="border border-gray-300 rounded text-gray-900 w-full border py-3  px-3 h-10 outline-0"
              thousandSeparator=" " prefix={`${currency}` }/>
          </div>

          {(data.type === 2 && data.is_limited && amount < data.limits.min) && (
            <span className="text-red-600">Le montant minimun est de {data.limits.min}</span>
          )}

          <div className="mb-5 mt-10">
            <p className="text-gray-500 "> {data.description}</p>
          </div>

        </div>
      )}


    </div>
  );
}

export default PaymentLinkComponent;