import React from 'react';
import './style.css'
import NumberFormat from "react-number-format";
import YabetooLogo from "../icons/yabetooLogo";
import Spinner from "../icons/spinner";

function Index(props) {
  return (
    <div className="w-full h-screen flex items-center justify-center flex-col">
      <Spinner/>
      <YabetooLogo className="h-10 mt-6"/>
    </div>
  );
}

export default Index;