import {combineReducers, configureStore} from '@reduxjs/toolkit';
import { logger } from 'redux-logger'
import global from "./global";
import order from "./order";
import thunk from "redux-thunk"
import payment from "./payment";
import InvoiceSlice from "./features/invoiceSlice";



const reducers = combineReducers({
    global: global,
    order: order,
    payment: payment,
    invoice: InvoiceSlice,

});


const middlewares = [thunk]

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
}

export const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: middlewares,
});


